.first_section {
	height: calc(100vh - 90px);
	background-image: url(../../assets/home1.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	color: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
}

.first_section h1 {
	margin-bottom: 30px;
}

.first_section div {
	display: flex;
	align-items: center;
	gap: 10px;
}

.first_section button:first-of-type {
	background-color: var(--one);
	border: 1px solid var(--one);
}

.first_section button:last-of-type {
	background-color: transparent;
	color: var(--white);
	border: 1px solid var(--white);
}

.first_section button:first-of-type:hover {
	border-color: var(--white);
	color: var(--white);
}

.first_section button:first-of-type::before {
	background-color: var(--three);
}

.first_section button:last-of-type::before {
	background-color: var(--one);
}
.first_section button:last-of-type:hover {
	color: var(--three);
	border-color: var(--three);
}

@media (max-width: 900px) {
	.first_section {
		height: calc(100vh - 70px);
	}
	.first_section h1 {
		font-size: 40px;
	}
}

/* Third  Section*/

.third {
	background-color: var(--three);
	color: var(--white);
	text-transform: uppercase;
	text-align: right;
	overflow: hidden;
	padding: 10px 0;
}

.third h2 {
	white-space: nowrap;
	word-spacing: 50px;
	animation: marque 10s linear infinite;
	-webkit-animation: marque 10s linear infinite;
}

.third span {
	color: var(--one);
}

@keyframes marque {
	0% {
		transform: translate(100%, 0);
		-webkit-transform: translate(100%, 0);
		-moz-transform: translate(100%, 0);
		-ms-transform: translate(100%, 0);
		-o-transform: translate(100%, 0);
	}
	100% {
		transform: translate(-100%, 0);
		-webkit-transform: translate(-100%, 0);
		-moz-transform: translate(-100%, 0);
		-ms-transform: translate(-100%, 0);
		-o-transform: translate(-100%, 0);
	}
}

/* Section Four  */

.fourth {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;
	color: var(--three);
}

.fourth .text {
	width: 50%;
}

.fourth .text > h2 {
	font-size: 30px;
}

.fourth .text > p:last-of-type {
	margin: 20px 0;
	width: 70%;
	color: var(--four);
}

.fourth .text .text_one,
.fourth .text .text_two {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	gap: 20px;
	background-color: var(--six);
}

.fourth .text .text_one {
	margin-bottom: 20px;
}
.fourth .text .text_one img,
.fourth .text .text_two img {
	width: 50%;
}

.fourth .text .text_one p,
.fourth .text .text_two p {
	width: 80%;
	margin-top: 10px;
	color: var(--four);
}

.fourth_image_container {
	position: relative;
	text-align: right;
}

.fourth_image_container img {
	width: 80%;
}

.fourth_image_container button {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
}

.fourth_image_container button:hover {
	color: var(--white);
}

.fourth_image_container button::before {
	background-color: var(--three);
}

@media (max-width: 1000px) {
	.fourth {
		flex-direction: column;
	}
	.fourth .text {
		width: 100%;
	}
	.fourth_image_container {
		text-align: center;
	}

	.fourth .text .text_one p,
	.fourth .text .text_two p {
		width: 100%;
	}
}

/* Section Five  */

.five {
	padding: 20px 0;
	margin-bottom: 8rem;
	border-top: 1px solid var(--seven);
	border-bottom: 1px solid var(--seven);
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 40px;
}

.five_box {
	display: flex;
	align-items: center;
	gap: 20px;
	background-color: var(--six);
	padding: 10px;
}

.five_box h4 {
	padding: 5px;
	background-color: var(--three);
	color: var(--white);
}

.five_box:last-of-type > i {
	background-color: var(--three);
	padding: 8px;
	color: var(--white);
}

.five_box:last-of-type > div i {
	color: var(--yellow);
}

/* section Six  */

.swipe_content {
	display: flex;
	align-items: center;
	gap: 20px;
	color: var(--three);
	background-color: var(--six);
	padding: 20px;
}

.six_image_holder {
	position: relative;
}

.swipe_content .trainer {
	width: 120px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.six_image_holder img:nth-child(2) {
	position: absolute;
	left: 0;
	top: 0;
	width: 30px;
}

.swipe_content .six_text {
	padding: 0 10px;
	border-left: 1px solid var(--four);
}

.six_text p:first-of-type {
	margin: 10px 0;
}

.starts i {
	color: var(--yellow);
}

.six_text p:last-of-type {
	margin-top: 10px;
	color: var(--four);
}

/* Section Eight */

.eight {
	background-color: var(--two);
}

.eight_content {
	position: relative;
	display: flex;
	align-items: flex-start;
	gap: 20px;
	color: var(--white);
}
.eight_content::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	background-color: var(--two);
	width: 100%;
	height: 100%;
	opacity: 0.3;
}

.eight_content > div {
	width: 50%;
}

.eight_first p:first-of-type {
	letter-spacing: 2px;
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 10px;
}

.eight_first p:last-of-type {
	margin: 20px 0;
	width: 80%;
}

.eight_first img {
	width: 70%;
}

.eight_second > div {
	border-top: 1px solid var(--white);
	border-bottom: 1px solid var(--white);
	margin-bottom: 30px;
	padding: 15px 0;
}

.eight_second > div h5 {
	position: relative;
	background-color: var(--three);
	color: var(--white);
	width: fit-content;
	padding: 5px 10px;
	margin-bottom: 10px;
}

.eight_second > div h5 i {
	margin-right: 10px;
	color: var(--one);
}

@media (max-width: 900px) {
	.eight_content {
		flex-direction: column;
	}
	.eight_content > div {
		width: 100%;
	}

	.eight_first p:last-of-type {
		width: 100%;
	}
}

/* Section nine */

.nine {
	text-align: center;
	color: var(--three);
}
.nine > div:first-of-type {
	margin-bottom: 40px;
}

.plans {
	display: grid;
	/* grid-template-columns: auto auto auto auto;; */
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 30px;
}

.plans .plan {
	padding: 20px;
	background-color: var(--six);
}

.plan > div {
	margin: 20px 0;
	padding: 30px 0;
	border-top: 1px solid var(--seven);
	border-bottom: 1px solid var(--seven);
}

.plan h3 i {
	margin-right: 10px;
}

.plan > div p {
	padding: 8px;
	width: fit-content;
	margin: 10px auto 0;
	font-size: 15px;
}

.plan:nth-child(2) > div p {
	background-color: var(--one);
}

.plan ul li {
	margin-bottom: 25px;
}

.plan ul li:first-of-type {
	color: var(--two);
	font-weight: 500;
}

.plan button {
	margin: 0 auto;
	background-color: var(--three);
	color: var(--white);
}

.plan button:hover {
	color: var(--three);
}

.plan button::before {
	background-color: var(--one);
}

.plan:first-of-type h3 i {
	color: #ed2e2e;
}

.plan:nth-child(2) h3 i {
	color: var(--two);
}
.plan:last-of-type h3 i {
	color: #ffe182;
}

@media (max-width: 360px) {
	.plans {
		grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	}
}

/* section ten */

.ten {
	color: var(--three);
}

.ten div {
	padding: 10px;
	width: 500px;
	margin: 30px auto;
	background-color: var(--six);
}

.ten div span:first-of-type {
	margin-right: 20px;
	color: var(--two);
	font-weight: 500;
}

.ten div h3 {
	margin: 20px 0;
}

.ten div p {
	color: var(--four);
	font-size: 15px;
}

.ten div button {
	margin: 20px 0;
	border: 1px solid var(--three);
}

.ten div button:hover {
	color: var(--white);
	border-color: var(--white);
}

.ten div button::before {
	background-color: var(--three);
}

.ten div:last-of-type {
	position: relative;
	color: var(--white);
	background-image: url(../../assets/man.png);
	background-size: cover;
	background-repeat: no-repeat;
}

.ten div:last-of-type p,
.ten div:last-of-type h3,
.ten div:last-of-type span {
	position: relative;
	color: var(--seven);
}

.ten div:last-of-type::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #00000099;
}

@media (max-width: 550px) {
	.ten div {
		width: 100%;
	}
}

/* @media (max-width: 380px) {
  .plans {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
} */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.plans {
		grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.plans {
		grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
	}
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {.plans {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {.plans {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {.plans {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }} */
